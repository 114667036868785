import axios from '@/config/httpConfig'

// 获取视图详细信息
export function GetViewInfo(data) {
    return axios.post('/view/getViewInfo', data)
}

// 获获取选项列表值
export function GetViewGetSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}
// 获取币种信息
export function GetCurrencyInfo(data) {
    return axios.post('/currency/getCurrencyInfo', data)
}
//查询伙伴账户启用设置
export function queryPartnerFundSetup(data) {
    return axios.get('/partnerFund/queryPartnerFundSetup', data)
}
// 查询支付设置
export function getPaymentSettings(data) {
    return axios.post('partnerSetup/getPaymentSettings', data)
}
// 线上支付设置
export function savePaymentSetting(data) {
    return axios.post('partnerSetup/savePaymentSetting', data)
}
// 查询微信支付配置信息
export function getWXpayMessage(data) {
    return axios.post('partnerSetup/getWXpayMessage', data)
}
// 查询支付宝支付配置信息
export function getALiPayMessage(data) {
    return axios.post('partnerSetup/getALiPayMessage', data)
}
// 保存微信支付商家信息
export function saveWXPayMessage(data) {
    return axios.post('partnerSetup/saveWXPayMessage', data)
}
// 保存支付宝支付商家信息
export function saveALiPayMessage(data) {
    return axios.post('partnerSetup/saveALiPayMessage', data)
}
//保存伙伴账户设置
export function savePartnerFund(data) {
    return axios.post('/partnerFund/savePartnerFund', data)
}
// 查询客户筛选条件
export function getAccountCondition(data) {
    return axios.post('/partnerFund/getAccountCondition', data)
}
// 保存客户筛选条件
export function saveAccountCondition(data) {
    return axios.post('/partnerFund/saveAccountCondition', data)
}

// 获取记录类型
export function getRecordType(data) {
    return axios.post('/batch/getRecordType', data)
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
    return axios.post('objectdetail/queryMoreDataByName', data)
}