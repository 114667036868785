<template>
  <div class="settingBodys">
    <div class="settingBody" v-loading="loadData">
      <!-- title -->
      <!-- 伙伴账户设置 -->
      <div class="title">
        {{ $t("label.partnerCloud.financeSetting.accountSettings") }}
      </div>
      <!-- 复选框 -->
      <div class="checkboxGroup">
        <!-- 启用伙伴账户 -->
        <el-checkbox v-model="openAccount" style="margin-right: 5px">{{
          $t("label.partnerCloud.basicSetting.partnerPayment")
        }}</el-checkbox>
        <!-- 启用后，会添加伙伴账户与账户流水对象，合作伙伴用户在下单时可以使用cloudcc系统内的账户支付。 -->
        <el-tooltip
          popper-class="my-tooltip"
          class="item"
          effect="dark"
          :content="$t('label.partnerCloud.financeSetting.account')"
          placement="top"
        >
          <div
            class="el-icon-question"
            style="color: #888888; margin-right: 40px"
          ></div>
        </el-tooltip>
        <!-- 启用返利账户 -->
        <el-checkbox v-model="openRebate" style="margin-right: 5px">{{
          $t("label.partnerCloud.financeSetting.enableAccount")
        }}</el-checkbox>
        <!-- 启用后，会添加返利帐户、返利收入、返利支出对象，合作伙伴用户在下单结算时可以使用返利帐户内的余额抵扣金额 -->
        <el-tooltip
          popper-class="my-tooltip"
          class="item"
          effect="dark"
          :content="$t('label.partnerCloud.financeSetting.deduction')"
          placement="top"
        >
          <div
            class="el-icon-question"
            style="color: #888888; margin-right: 40px"
          ></div>
        </el-tooltip>
        <br />
        <!-- 新的合作伙伴启用时，为符合条件的客户自动创建账户 -->
        <el-checkbox
          v-model="autoCreate"
          v-if="openAccount == true || openRebate == true"
          style="margin: 17px 0px"
          >{{ $t("label.partnerCloud.financeSetting.create") }}</el-checkbox
        >
      </div>
      <!-- 筛选器 v-show="autoCreate" -->
      <div v-show="autoCreate && (openAccount == true || openRebate == true)">
        <!-- title -->
        <!-- 筛选条件 -->
        <div class="title">
          {{ $t("label.partnerCloud.financeSetting.filter") }}
        </div>
        <!-- 筛选器主体 -->
        <div class="filterBody">
          <div class="range">
            <!-- 筛选字段文本框 -->
            <el-form>
              <el-form-item
                v-for="(val, index) in pushData"
                :key="val.id"
                :label="index + 1 + '.'"
                label-width="50px"
              >
                <div class="vl">
                  <!-- 筛选字段 开始 -->
                  <el-select
                    v-model="val.fieldId"
                    :placeholder="$t('label.selectfield')"
                    filterable
                    popper-class="option"
                    :popper-append-to-body="false"
                    clearable
                  >
                    <el-option
                      v-for="(items, indexs) in choseFieldList"
                      :key="indexs"
                      :label="items.labelName"
                      :value="items.id"
                      @click.native="handleCommand(val, items, items)"
                    >
                      <!-- val:传递当前字段的信息 -->
                      <!-- items:传递 -->
                    </el-option>
                  </el-select>
                  <!-- 筛选条件 开始 -->
                  <el-select
                    v-model="val.op"
                    :placeholder="$t('label.report.select.condition')"
                    class="select"
                    filterable
                    popper-class="option"
                    :popper-append-to-body="false"
                    clearable
                  >
                    <el-option
                      v-for="item in conditions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
                <span>
                  <!-- 值 -->
                  {{ $t("label.campaign.channel.value") }}
                </span>
                <!-- <input
                  v-model="val.val.value"
                  :data-index="index"
                  style="width: 66px"
                  type="text"
                  name=""
                />-->
                <EditableCell
                  style="
                    width: 200px;
                    display: inline-block;
                    margin-left: 10px;
                    position: relative;
                  "
                  class="editableCell"
                  ref="editableCell"
                  :options="selector"
                  :value="val.val"
                  :input-type="val.inputType"
                  :prefix="prefix"
                  :fieldId="val.fieldId"
                  :objid="val.objid"
                  :min="0"
                  :max="1000000000000000000"
                  :origin-type="filter"
                  @selectes="val.fieldId === '' ? '' : selectes(val)"
                  @remoteSearch="remoteSearch(val, index)"
                  @remoteMethods="remoteMethods"
                >
                </EditableCell>
                <!-- 加减按钮组 -->
                <!-- 只有一个的时候不显示 -->
                <div style="display: inline-block; margin-top: 5px">
                  <el-button
                    type="info"
                    size="mini"
                    @click="updateItem('reduce', index)"
                    v-show="pushData.length !== 1"
                    >-</el-button
                  >
                  <el-button
                    type="info"
                    size="mini"
                    @click="updateItem('add', index)"
                    v-if="index + 1 == pushData.length && index != 4"
                    >+</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
            <div>
              <el-dialog
                :title="$t('label.searchs')"
                custom-class="dialog-form-wrapper"
                width="60%"
                top="5%"
                style="height: 91%"
                :visible.sync="showSearchTable"
                :lock-scroll="true"
                :close-on-click-modal="false"
                :modal="true"
                append-to-body
              >
                <search-table
                  ref="searchTable"
                  :fieldId="fieldId"
                  :checked="checked"
                  :relevant-objid="relevantObjId"
                  :relevant-prefix="relevantPrefix"
                  @changeSelect="changeSelect"
                />
              </el-dialog>
            </div>
          </div>
        </div>
        <!-- 高级筛选条件 -->
        <div class="rangeS">
          <!-- 高级筛选条件    （例如：1 And 2 Or 3 ）  -->
          <div class="rangeTitle">
            {{ $t("label.partnerCloud.financeSetting.gaofilter") }}
          </div>
          <el-input class="innerWidth" v-model="range"></el-input>
        </div>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="btnGroup">
      <el-button
        style="margin-left: 10px; color: white"
        type="primary"
        size="mini"
        @click="saveSettup"
      >
        <!-- 保存 -->
        {{ $t("label.save") }}
      </el-button>
      <el-button @click="cancleSetup" size="mini">
        <!-- 取消 -->
        {{ $t("label.cancel") }}
      </el-button>
    </div>
    <!-- 分割线 -->
    <div class="line"></div>
    <div class="settingBody">
      <!-- title -->
      <!-- 线上支付 -->
      <div class="title">
        {{ $t("label.partnerCloud.financeSetting.onlinePayment") }}
      </div>
      <div class="pay-card">
        <div class="logo">
          <!-- logo图片 -->
          <svg class="icon" aria-hidden="true">
            <use href="#icon-weixin11"></use>
          </svg>
          <!-- 显示启用与未启用 -->
          <div class="word">{{ wxIsUser }}</div>
        </div>
        <!-- 支付宝描述 -->
        <!-- 需开通微信服务号，并与腾讯公司签约收款服务；交易手续费由腾讯公司向收款方收取。 -->
        <div class="describe">
          {{ $t("label.partnerCloud.financeSetting.weChat") }}
        </div>
        <div class="operation">
          <!-- 查看指南 -->
          <!-- <div class="guide">查看指南</div> -->
          <!-- 配置按钮 -->
          <!-- 配置 -->
          <div class="button" @click="wxOpera">
            {{ $t("label.textmessage.vonage.configurations") }}
          </div>
        </div>
      </div>
      <!-- 支付宝微信设置卡片 -->
      <div class="pay-card" style="display: none">
        <div class="logo">
          <!-- logo图片 -->
          <svg class="icon zhifubao" aria-hidden="true">
            <use href="#icon-zhifubao1"></use>
          </svg>
          <!-- 显示启用与未启用 -->
          <div class="word">{{ zfbIsUser }}</div>
        </div>
        <!-- 支付宝描述 -->
        <div class="describe">
          需开通企业支付宝账号，仅支持以企业对公账户作为收款账户。交易手续费由支付宝公司向收款方收取。
        </div>
        <div class="operation">
          <!-- 查看指南 -->
          <div class="guide">查看指南</div>
          <!-- 配置按钮 -->
          <div class="button" @click="zhifubaoOpera">配置</div>
        </div>
      </div>

      <!-- 允许订单线上付款 -->
      <div class="pay">
        <!-- 允许订单线上付款 -->
        <span>{{ $t("label.partnerCloud.financeSetting.payments") }}</span
        ><el-switch
          v-model="value1"
          @change="changePay"
          active-color="#409EFF"
          inactive-color="#C0CCDA"
        >
        </el-switch>
      </div>
    </div>
    <div class="line-select"></div>
    <div class="pay-bottom">
      <!-- 允许线上支付充值 -->
      <span>{{ $t("label.partnerCloud.financeSetting.paymentAllowed") }}</span
      ><el-switch
        v-model="value2"
        @change="changeRecharge"
        active-color="#409EFF"
        inactive-color="#C0CCDA"
      >
      </el-switch>
    </div>
    <!-- 设置支付宝支付 -->
    <el-dialog
      :visible.sync="zhifubaoSetUp"
      :title="$t('label.partnerCloud.financeSetting.setAlipay')"
      width="60%"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div class="form">
        <el-form
          label-position="top"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="50px"
        >
          <!-- 支付宝企业账户 -->
          <el-form-item
            :label="$t('label.partnerCloud.financeSetting.alipayCorporate')"
            prop="name"
          >
            <el-input v-model.trim="ruleForm.name"></el-input>
          </el-form-item>
          <!-- 合作者身份 -->
          <el-form-item
            :label="$t('label.partnerCloud.financeSetting.collaborator')"
            prop="role"
          >
            <el-input v-model.trim="ruleForm.role"></el-input>
          </el-form-item>
          <!-- MD5密钥 -->
          <el-form-item
            :label="$t('label.partnerCloud.financeSetting.MD5')"
            prop="secret"
          >
            <el-input v-model.trim="ruleForm.secret"></el-input>
          </el-form-item>
          <!-- 启用 -->
          <el-checkbox v-model="checked">{{
            $t("label.emailtocloudcc.button.enable")
          }}</el-checkbox>
        </el-form>
      </div>
      <!-- 如何设置支付宝签约信息 -->
      <div class="text">
        <h2>{{ $t("label.partnerCloud.financeSetting.information") }}</h2>
        <!-- 1.访问支付宝商家中心（b.alipay.com），用签约支付宝账户登录。 -->
        <p>{{ $t("label.partnerCloud.financeSetting.informationOne") }}</p>
        <!-- 2.商家中心首页点击【账户管理】-【商户信息管理】-【产看PIDIKEY】-【密钥管理】。 -->
        <p>
          {{ $t("label.partnerCloud.financeSetting.informationTwo") }}
        </p>
        <!-- 3.进入密钥管理界面后，点击【mapi网关产品密钥】，获取合作伙伴身份和MD5密钥。 -->
        <p>
          {{ $t("label.partnerCloud.financeSetting.informationThree") }}
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <!-- 保存 -->
        <el-button type="primary" @click="save('ruleForm')">{{
          $t("component.telerecord.button.save")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 设置微信支付 -->
    <el-dialog
      :visible.sync="wxSetUp"
      :title="$t('label.partnerCloud.financeSetting.setWechatPay')"
      width="60%"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div class="wx-form">
        <el-form
          label-position="top"
          :model="wxForm"
          :rules="wxRules"
          ref="wxForm"
          label-width="50px"
        >
          <!-- 开发者ID -->
          <el-form-item :label="$t('label.partnerCloud.financeSetting.developerIDTwo')" prop="appid">
            <!-- 获取路径：公众平台-开发-基本配置 -->
            <el-input v-model.trim="wxForm.appid"></el-input
            ><span>{{
              $t("label.partnerCloud.financeSetting.basicConfiguration")
            }}</span>
          </el-form-item>
          <el-form-item label="Appsecret" prop="secret">
            <el-input v-model.trim="wxForm.secret"></el-input
            ><span>{{
              $t("label.partnerCloud.financeSetting.basicConfiguration")
            }}</span>
          </el-form-item>
          <!-- API密钥 -->
          <el-form-item
            :label="$t('label.textmessage.vonage.api.key')"
            prop="key"
          >
            <!-- 获取路径：商户平台-账户中心-API安全-API密钥 -->
            <el-input v-model.trim="wxForm.key"></el-input
            ><span>{{
              $t("label.partnerCloud.financeSetting.merchantPlatform")
            }}</span>
          </el-form-item>
          <!-- 微信支付商户号 -->
          <el-form-item
            :label="$t('label.partnerCloud.financeSetting.merchantNumber')"
            prop="mch_id"
          >
            <!-- 获取路径：商户平台-账户中心-商户信息 -->
            <el-input v-model.trim="wxForm.mch_id"></el-input
            ><span>{{
              $t("label.partnerCloud.financeSetting.merchantInformation")
            }}</span>
          </el-form-item>
          <!-- 启用 -->
          <el-checkbox v-model="wxChecked">{{
            $t("label.emailtocloudcc.button.enable")
          }}</el-checkbox>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="wxCancle">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <!-- 保存 -->
        <el-button type="primary" @click="wxSave('wxForm')">{{
          $t("component.telerecord.button.save")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryPartnerFundSetup,
  getPaymentSettings,
  savePaymentSetting,
  getWXpayMessage,
  getALiPayMessage,
  saveWXPayMessage,
  saveALiPayMessage,
  savePartnerFund,
  getAccountCondition,
  saveAccountCondition,
} from "./api";
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import * as CommonObjApi from "./api";
import operator from "@/config/enumCode/operator.js";
import inputType from "@/config/enumCode/inputType.js";
import {
  GetViewInfo,
  GetViewGetSelectValue,
  GetCurrencyInfo,
} from "./api";
import { echoValueTypeConversion } from "@/config/filter/commaProcessing";
export default {
  components: {
    EditableCell,
  },
  data() {
    return {
      loadData: false,
      allFieldList: [],
      range: "",
      objId: "account",
      viewId: "aec201402485474VOvRy",
      relevantObjId: "",
      relevantPrefix: "",
      checked: false,
      fieldId: "",
      showSearchTable: false,
      filter: "filter",
      prefix: "002",
      choseFieldList: [],
      selector: [],
      // 运算符
      conditions: [],
      // 保存筛选字段
      pushData: [
        {
          id: 0,
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          inputType: "input",
          objid: "",
        },
      ],
      pushDatas: {
        fieldId: "",
        op: "",
        val: {
          value: "",
        },
        inputType: "input",
        objid: "",
      },
      openAccount: false, //启用伙伴账户
      openRebate: false, //启用返利账户
      autoCreate: false, //自动创建
      wxIsUser: "",
      zfbIsUser: "",
      value1: false, //允许订单线上付款
      value2: false, //允许线上支付充值
      zhifubaoSetUp: false, //支付宝设置弹框
      wxSetUp: false, //微信设置弹框
      wxChecked: false,
      ruleForm: {
        name: "",
        role: "",
        secret: "",
      },
      wxForm: {
        appid: "",
        secret: "",
        key: "",
        mch_id: "",
      },
      wxRules: {
        appid: [
          {
            required: true,
            message: this.$i18n.t(
              "label.partnerCloud.financeSetting.developerID"
            ),
            trigger: "blur",
          }, //请输入开发者ID
        ],
        secret: [
          {
            required: true,
            message: this.$i18n.t(
              "label.partnerCloud.financeSetting.enterAppsecret"
            ),
            trigger: "blur",
          }, //请输入Appsecret
        ],
        key: [
          {
            required: true,
            message: this.$i18n.t(
              "label.partnerCloud.financeSetting.enterApiKey"
            ),
            trigger: "blur",
          }, //请输入API密钥
        ],
        mch_id: [
          {
            required: true,
            message: this.$i18n.t(
              "label.partnerCloud.financeSetting.enterWechat"
            ),
            trigger: "blur",
          }, //请输入微信支付商户号
        ],
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$i18n.t(
              "label.partnerCloud.financeSetting.enterAlipay"
            ),
            trigger: "blur",
          }, //请输入支付宝企业账户
        ],
        role: [
          {
            required: true,
            message: this.$i18n.t(
              "label.partnerCloud.financeSetting.enterCollaborator"
            ),
            trigger: "blur",
          }, //请输入合作者身份
        ],
        secret: [
          {
            required: true,
            message: this.$i18n.t(
              "label.partnerCloud.financeSetting.enterMd5Key"
            ),
            trigger: "blur",
          }, //请输入MD5密钥
        ],
      },
    };
  },
  async mounted() {
    this.loadData = true;
    this.getPaymentSettings();
    this.getPartnerFundSetup();
    await this.choseField();
    await this.getAccountConditions();
  },
  beforeDestroy() {},
  created() {},
  methods: {
    async getRecordTypes() {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      let res = await CommonObjApi.getRecordType(params);
      if (res.result) {
        res.data.recordTypeList.forEach((item) => {
          let obj = {
            label: item.name,
            value: item.name,
          };
          arr.push(obj);
          this.selector = arr;
        });
      }
    },
    openResult() {
      // 如果开启了高级筛选
      // if (this.advancedFilter == true) {
      // 遍历参数数组
      let arrData = [];
      this.pushData.map((item) => {
        let object = {
          fieldId: item.fieldId,
          op: item.op,
          val: "",
        };
        arrData.push(object);
      });
      this.$refs.editableCell.forEach((item, index) => {
        if (
          item.inputType === "remote-select" ||
          item.inputType === "remote-multi-select"
        ) {
          arrData[index].val = item.value;
        } else {
          arrData[index].val = item.editValue;
        }
      });
      if (arrData.length > 0) {
        // 验证筛选器是否填写完整
        for (var key in arrData) {
          var item = arrData[key];
          if (item.fieldId !== "") {
            if (item.val.value == "" || item.op == "") {
              this.$message({
                showClose: true,
                type: "warning",
                // 请填写完整筛选条件
                message: this.$i18n.t(
                  "vue_label_checkduplicate_complete_filter_rules"
                ),
              });
              return;
            }
          }
          var conditionData = JSON.stringify({
            data: arrData,
            filter: this.range ? this.range : "",
          });
        }
        this.saveAccountConditions({
          conditionVals: conditionData,
          relatedId: "202107071saveaccount",
          mainObjId: "account",
        });
      }
    },
    // 保存客户筛选条件
    async saveAccountConditions(obj) {
      await saveAccountCondition(obj);
    },
    // 查询客户筛选条件
    async getAccountConditions() {
      let result = await getAccountCondition({
        relateId: "202107071saveaccount",
      });
      this.loadData = false;
      if (result.data.length > 0) {
        this.pushData = [];
        this.range = result.data[0].boolFilter;
        result.data.map((ele, index) => {
          let finalValue = null;
          // 不是json串
          if (
            ele.value.indexOf('{"') !== -1 ||
            ele.value.indexOf('["') !== -1
          ) {
            finalValue = JSON.parse(ele.value);
          } else {
            finalValue = ele.value;
          }
          this.pushData.push({
            id: index,
            fieldId: ele.fieldId,
            op: ele.operator,
            val:
              typeof finalValue == "object"
                ? finalValue
                : { value: finalValue },
            inputType: "input",
          });
          this.pushData.forEach((nav) => {
            this.allFieldList.forEach((em) => {
              if (nav.fieldId == em.id) {
                nav.type = em.schemefieldType;
                this.conditions =
                  operator.TYPE[nav.type].getScreeningOpeTypes();
                let inputTypes =
                  inputType[
                    em.schemefieldType === "L" || em.schemefieldType === "R"
                      ? "Q"
                      : em.schemefieldType
                  ];
                nav.inputType = inputTypes;
              }
            });
          });
        });
        this.pushData.map((item) => {
          if (item.type == "ct") {
            GetCurrencyInfo().then((res) => {
              res.data.map((ele) => {
                if (item.val.value == ele.currencycode) {
                  item.val.value = ele.currencyname;
                } else {
                  let obj = {
                    label: ele.currencyname,
                    value: ele.currencycode,
                  };
                  this.selector.push(obj);
                }
              });
            });
          }
          // 选择框类型为查找多选字段时，才需要回显做处理
          if (item.inputType === "multi-select") {
            item.val = { value: echoValueTypeConversion(item.val.join()) };
          }
        });
      }
    },
    async selectes(val) {
      if (val.type === "R") {
        this.getRecordTypes();
      } else {
        if (val.fieldId === "ffe20120329185449704") {
          return;
        }
        let arr = [];
        let params = {
          fieldId: val.fieldId,
        };
        let res = await GetViewGetSelectValue(params);
        if (res.result) {
          res.data.forEach((item) => {
            let obj = {
              label: item.codevalue,
              value: item.codevalue,
            };
            arr.push(obj);
            this.selector = arr;
          });
        }
      }
    },
    //判断选择添加如果选择字段获取的值为S则调用S对象,获取的值为D则调用D对象,获取的值为F则调用F对象,获取的值为O则调用O对象
    async handleCommand(item, key, index) {
      this.selector = [];
      let keys = "";
      if (key.schemefieldType === "Z") {
        keys = key.expressionType;
      } else {
        keys = key.schemefieldType;
      }
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      this.pushData.map((item, indexs) => {
        if (index.id === item.fieldId) {
          item.op = this.conditions[0].id;
          item.type =
            key.schemefieldType === "Z"
              ? key.expressionType
              : key.schemefieldType;
          this.$refs.editableCell[indexs].editValue = "";
          item.objid = key.schemetableId;
        }
      });
      this.pushData.forEach((items) => {
        if (item.id === items.id) {
          item.inputType = inputType[keys === "L" ? "Q" : keys];
        }
      });
      // 当前选中字段类型
      this.conditions = operator.TYPE[keys].getScreeningOpeTypes();
      if (keys == "ct") {
        let res = await GetCurrencyInfo();
        res.data.forEach((item) => {
          let obj = {
            label: item.currencyname,
            value: item.currencycode,
          };
          this.selector.push(obj);
        });
      }
    },
    // 改变查找/查找带值选项及值
    changeSelect(row) {
      // let value = {
      //   value: row.data.id,
      // };
      let options = {
        label: row.data.id,
        value: row.data.name,
      };
      this.$set(this.pushData[this.rowIndex - 1], "val", options);
      this.showSearchTable = false;
    },
    remoteSearch(item, index) {
      if (item.fieldId !== "") {
        this.fieldId = item.fieldId;
      }
      this.rowIndex = index + 1;
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    updateItem(handle, index) {
      if (handle == "add") {
        if (this.pushData.length <= 4) {
          this.pushData.push({
            ...this.pushDatas,
            // id: this.num,
          });
        }
      } else {
        this.pushData.splice(index, 1);
        this.$refs.editableCell.splice(index, 1);
      }
    },
    async choseField() {
      let params = {
        objId: this.objId,
        viewId: this.viewId,
      };
      let result = await GetViewInfo(params);
      this.choseFieldList = result.data.conditionAllFieldList;
      this.allFieldList = result.data.conditionAllFieldList;
    },
    remoteMethods(val, fieldId) {
      let params = {
        fieldId: fieldId,
        objId: "",
        prefix: "",
      };
      CommonObjApi.getLookupInfo(params).then((res) => {
        let data = {
          name: val,
          objId: res.data.prefix,
          fieldId: params.fieldId,
          lkdp: "['']",
        };
        CommonObjApi.queryMoreDataByName(data).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.selector = options;
        });
      });
    },
    // 保存伙伴账户是否启用设置
    saveSettup() {
      if (this.autoCreate) {
        this.openResult();
      }
      this.saveSettupInformation();
    },
    // 取消伙伴账户设置
    cancleSetup() {
      this.getPartnerFundSetup();
    },
    // 获取伙伴账户启用设置
    async getPartnerFundSetup() {
      let result = await queryPartnerFundSetup();
      if (result.result) {
        this.openAccount = result.data.activate == "false" ? false : true;
        this.openRebate = result.data.isRebateAccount == "false" ? false : true;
        this.autoCreate = result.data.isAutoCreated == "false" ? false : true;
      }
    },
    
    async saveSettupInformation() {
      let obj = {
        activate: this.openAccount,
        isAutoCreated: this.autoCreate,
        isRebateAccount: this.openRebate,
      };
      let result = await savePartnerFund(obj);
      if (result.result) {
        this.$message({
          showClose: true,
          type: "success",
          message: this.$i18n.t("label.search.saveok"),
        });
      }
    },
    //保存微信支付商家信息
    async saveWXPayMessage() {
      let option = {
        appid: this.wxForm.appid,
        isUseWXPay: this.wxChecked.toString(),
        mchid: this.wxForm.mch_id,
        secret: this.wxForm.secret,
        wxkey: this.wxForm.key,
      };
      let res = await saveWXPayMessage(option);
      if (res.result == true) {
        this.wxSetUp = false; //已启用   未启用
        this.wxIsUser =
          this.wxChecked == true
            ? this.$i18n.t("label.inusing")
            : this.$i18n.t("label.nousing");
        if (!this.wxChecked && !this.checked) {
          let result = await savePaymentSetting({
            isOnlinePayment: "false",
            isPayRecharge: "false",
          });
          if (result.result) {
            this.value1 = false;
            this.value2 = false;
          }
        }
        this.$message.success(this.$i18n.t("label.search.saveok")); //保存成功
      }
    },
    //保存支付宝支付商家信息
    async saveALiPayMessage() {
      let option = {
        alipayAccount: this.ruleForm.name,
        isUseALiPay: this.checked.toString(),
        partnerStatus: this.ruleForm.role,
        alipayMD5: this.ruleForm.secret,
      };
      let res = await saveALiPayMessage(option);
      if (res.result == true) {
        //已启用  未启用
        this.zfbIsUser =
          this.checked == true
            ? this.$i18n.t("label.inusing")
            : this.$i18n.t("label.nousing");
        this.zhifubaoSetUp = false;
        if (!this.wxChecked && !this.checked) {
          let result = await savePaymentSetting({
            isOnlinePayment: "false",
            isPayRecharge: "false",
          });
          if (result.result) {
            this.value1 = false;
            this.value2 = false;
          }
        }
        this.$message.success(this.$i18n.t("label.search.saveok"));
      }
    },
    //更改线上付款状态
    async changePay(val) {
      // 未启用  未启用
      if (
        this.wxIsUser == this.$i18n.t("label.nousing") &&
        this.zfbIsUser == this.$i18n.t("label.nousing")
      ) {
        // 请先配置至少一个线上支付账户
        this.$message.warning(this.$i18n.t("label.savepayset.failure.nopay"));
        this.value1 = !val;
        return false;
      }
      let result = await savePaymentSetting({
        isOnlinePayment: val == true ? "true" : "false",
        isPayRecharge: this.value2.toString(),
      });
      if (result.result) {
        if (val == true) {
          this.$message.success(this.$i18n.t("label.group.isusing.success")); //启用成功
        }
      }
    },
    //更改线上充值状态
    async changeRecharge(val) {
      if (
        this.wxIsUser == this.$i18n.t("label.nousing") &&
        this.zfbIsUser == this.$i18n.t("label.nousing")
      ) {
        // 请先配置至少一个线上支付账户
        this.$message.warning(this.$i18n.t("label.savepayset.failure.nopay"));
        this.value2 = !val;
        return false;
      }

      let result = await savePaymentSetting({
        isOnlinePayment: this.value1.toString(),
        isPayRecharge: val == true ? "true" : "false",
      });
      if (result.result) {
        if (val == true) {
          this.$message.success(this.$i18n.t("label.group.isusing.success")); //启用成功
        }
      }
    },
    //查询支付配置
    async getPaymentSettings() {
      let result = await getPaymentSettings();
      if (result.result == true) {
        this.value1 = eval(result.data.isOnlinePayment);
        this.value2 = eval(result.data.isPayRecharge);
        // 已启用  未启用
        this.wxIsUser =
          result.data.isUseWXPay == "true"
            ? this.$i18n.t("label.inusing")
            : this.$i18n.t("label.nousing");
        // 已启用  未启用
        this.zfbIsUser =
          result.data.isUseALiPay == "true"
            ? this.$i18n.t("label.inusing")
            : this.$i18n.t("label.nousing");
      }
    },
    //微信设置按钮方法
    async wxOpera() {
      this.wxSetUp = true;
      let res = await getWXpayMessage();
      if (res.result == true) {
        this.wxChecked = eval(res.data.isUseWXPay);
        this.wxForm.mch_id = res.data.mchid;
        this.wxForm.appid = res.data.appid;
        this.wxForm.secret = res.data.secret;
        this.wxForm.key = res.data.wxkey;
      }
    },
    //支付宝设置按钮方法
    async zhifubaoOpera() {
      this.zhifubaoSetUp = true;
      let res = await getALiPayMessage();
      if (res.result == true) {
        this.checked = eval(res.data.isUseALiPay);
        this.ruleForm.name = res.data.alipayAccount;
        this.ruleForm.role = res.data.partnerStatus;
        this.ruleForm.secret = res.data.alipayMD5;
      }
    },
    //支付宝设置保存
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveALiPayMessage();
        } else {
          return false;
        }
      });
    },
    //支付宝设置取消
    cancle() {
      this.ruleForm = {
        name: "",
        role: "",
        secret: "",
      };
      this.zhifubaoSetUp = false;
    },
    //微信设置保存
    wxSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveWXPayMessage();
        } else {
          return false;
        }
      });
    },
    //微信设置取消
    wxCancle() {
      this.wxSetUp = false;
    },
  },
};
</script>




<style lang="scss" scoped>
* {
  margin: 0;
  color: #080707;
}
.vl {
  display: inline-block;
}
.rangeS {
  margin-top: 10px;
  margin-left: 30px;
}
.rangeTitle {
  color: #1d2033;
  margin-bottom: 15px;
}
::v-deep .innerWidth input {
  width: 350px;
  height: 30px;
  margin-bottom: 15px;
}
.range button {
  width: 32px;
  height: 32px;
  margin-left: 10px;
}
::v-deep .el-input__inner {
  height: 32px;
}
::v-deep .el-input__icon {
  line-height: 20px;
}
::v-deep .el-form-item__label {
  line-height: 32px;
}
.vl {
  display: inline-block;
}
::v-deep .el-form-item__content {
  line-height: 27px;
}
::v-deep .el-select {
  margin-right: 10px;
}
::v-deep .el-form-item__label {
  line-height: 42px;
}
::v-deep .el-form-item {
  margin-bottom: 5px;
}
::v-deep .remoteBtn {
  position: absolute;
  right: 8px;
  top: 2px;
}
::v-deep .el-button--mini,
.el-button--mini.is-round {
  padding: 7px 11px;
}
::v-deep .el-button--info {
  background: #ffffff;
  border-color: #dcdfe6;
}
::v-deep .el-button--info {
  color: black;
}
::v-deep .el-input {
  width: 200px !important;
}
::v-deep .el-textarea__inner {
  transform: translateY(4px);
  min-height: 32px !important;
}
::v-deep .remoteBtn1 {
  top: 3px;
}
::v-deep .el-select__tags {
  margin-top: -6px;
  flex-wrap: unset;
  overflow-x: overlay;
  top: 66%;
  height: 55px;
  .el-tag {
    margin: 11px 7px 10px 5px;
  }
  .el-select__input {
    min-width: 50px !important;
  }
}
::v-deep .el-icon-question:before {
  color: #005fb2;
}
.form {
  width: 45%;
  margin-right: 5%;
  float: left;
  ::v-deep .el-form--label-top .el-form-item__label {
    padding: 10px 0 0;
  }
  ::v-deep .el-checkbox {
    margin-top: 20px;
  }
}
.wx-form {
  span {
    color: #606266;
  }
  ::v-deep .el-form--label-top .el-form-item__label {
    padding: 10px 0 0;
  }
  ::v-deep .el-checkbox {
    margin-top: 20px;
  }
  ::v-deep .el-input {
    width: 50%;
    margin-right: 50px;
  }
}
.text {
  width: 45%;
  height: 100%;
  float: right;
}
.line {
  width: 100%;
  height: 10px;
  background: #ededed;
}
.settingBody {
  padding: 10px;
}
.pay {
  margin-top: 20px;
  font-size: 12px;
  span {
    margin-right: 150px;
  }
}
.line-select {
  width: 100%;
  height: 1px;
  background: #dddbda;
}
.pay-bottom {
  padding: 10px;
  font-size: 12px;
  span {
    margin-right: 150px;
  }
}
.pay-card {
  display: inline-block;
  font-size: 12px;
  width: 300px;
  border: 2px solid #dddbda;
  padding: 10px;
  margin-right: 30px;
  border-radius: 3px;
  .logo {
    overflow: hidden;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      width: 80px;
      height: 40px;
    }
    .zhifubao {
      transform: translateX(-10px);
      width: 90px;
    }
  }
  .guide {
    float: left;
    color: #005fb2;
  }
  .operation {
    margin-top: 5px;
    overflow: hidden;
    .button {
      padding: 5px 25px;
      border-radius: 3px;
      background-color: #005fb2;
      float: right;
      color: white;
      cursor: pointer;
    }
    .guide {
      float: left;
      cursor: pointer;
    }
  }
}
.title {
  height: 30px;
  line-height: 30px;
  margin-bottom: 17px;
}
.btnGroup {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row-reverse;
  border-top: 1px solid #dddbda;
  border-bottom: 1px solid #dddbda;
  align-items: center;
  padding-right: 36px;
}
::v-deep .el-button {
  width: 74px;
  height: 28px;
}
::v-deep .el-button--primary {
  color: white;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #005fb2;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  color: #005fb2;
  background-color: #005fb2;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #005fb2;
}
::v-deep .el-switch.is-checked .el-switch__core {
  border-color: #005fb2 !important;
  background-color: #005fb2 !important;
}
::v-deep .el-select + .el-date-editor--datetime {
  display: none;
}
::v-deep .el-select + .el-date-editor {
  display: none;
}
.editableCell {
  position: relative;
}
</style>
